
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-2",
  props: {
    widgetClasses: String,
    title: String,
    description: String,
    avatar: String
  },
  components: {}
});
